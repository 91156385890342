import { Order } from "~~/types/Order";

export default function usePurchaseGa(booking: Order): void {
  const formatUKPhoneNumber = (
    phoneNumber?: string
  ): string | undefined => {
    if (!phoneNumber) return undefined;
    return `+44${phoneNumber.replace(/^0/, "")}`;
  };

  let reference = booking.reference;
  let equipment = booking.equipment;
  let items = [];

  equipment.forEach((item) => {
    items.push({
      item_id: item.type.id,
      item_name: item.type.name,
      currency: "GBP",
      price: item.price,
      quantity: 1,
    });
  });

  if (!process.client) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      transaction_id: reference,
      value: booking.totalPrice,
      tax: 0,
      shipping: 0,
      currency: "GBP",
      coupon: "",
      items: items,
    },
    user_data: {
      email_address: booking.customer?.email,
      phone_number: formatUKPhoneNumber(
        booking.customer?.phoneNumber
      ),
      address: {
        first_name: booking.customer?.firstName,
        last_name: booking.customer?.lastName,
        address_1: booking.address?.line1,
        city: booking.address?.city,
        zip: booking.address?.postcode,
        country: "UK",
      },
    },
  });
}
